<script setup lang="ts">
import { ref, nextTick, computed } from "vue";
import TextInputLine from "@/components/ui/TextInputLine.vue";
import { useI18n } from "vue-i18n";
import SearchMagnifyingGlass from "@/components/ui/icons/SearchMagnifyingGlass.vue";

const props = withDefaults(
  defineProps<{
    color?: string;
    iconSize?: number;
  }>(),
  { color: "black", iconSize: 25 }
);

const emit = defineEmits<{
  (e: "updateSearch", val: string): void;
}>();

const { t } = useI18n();

const searchText = ref<string>("");
const searchInputVisible = ref<boolean>(false);

const inputLineBorder = computed<string>(() => {
  return `1px solid ${props.color}`;
});

const handleSearchChange = (value: string) => {
  searchText.value = value;
  emit("updateSearch", value);
};
const activateSearch = async () => {
  searchInputVisible.value = true;
  await nextTick();
  (document.querySelector(".search-bar input") as HTMLInputElement)!.focus();
};

const clickOutsideSearchbar = () => {
  if (!searchInputVisible.value) return;
  searchInputVisible.value = false;
};
</script>

<template>
  <div v-click-outside="clickOutsideSearchbar" class="search-bar">
    <Transition name="slide-one">
      <div v-if="searchInputVisible" class="bar">
        <TextInputLine
          :inputName="'text'"
          :labelText="t('NAVBAR.SEARCH')"
          :value="searchText"
          @update="handleSearchChange"
          :disabled="false"
        />
      </div>
    </Transition>

    <SearchMagnifyingGlass :color="color" :size="props.iconSize" @click="activateSearch" />
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/base.css";
@import "@/assets/styles/transitions.css";

.search-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.search-bar .bar {
  margin-bottom: 1em;
}
:deep(.bar label) {
  color: v-bind('color');
}
:deep(.bar input) {
  border-bottom: v-bind('inputLineBorder');
  color: v-bind('color');
}

@media (--max-tablet-width) {
}

@media (--max-phone-width) {
  .search-bar .bar {
    width: 7.5rem;
  }
}
</style>
