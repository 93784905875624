import { ref } from "vue";
import { defineStore } from "pinia";
import { API } from "@/util/api/api_paths";
import { axiosInstance } from "@/util/api/axios_instance";
import camelcaseKeys from "camelcase-keys";

type StatusMarkerTypeKebabCase = "critical-low" | "low" | "lower" | "optimal" | "higher" | "high" | "critical-high";

export enum StatusMarkerType {
  CRITICAL_LOW = "criticalLow",
  LOW = "low",
  LOWER = "lower",
  OPTIMAL = "optimal",
  HIGHER = "higher",
  HIGH = "high",
  CRITICAL_HIGH = "criticalHigh",
}
export const StatusMarkerTypeKeys: { [key in StatusMarkerTypeKebabCase]: StatusMarkerType } = {
  "critical-low": StatusMarkerType.CRITICAL_LOW,
  low: StatusMarkerType.LOW,
  lower: StatusMarkerType.LOWER,
  optimal: StatusMarkerType.OPTIMAL,
  higher: StatusMarkerType.HIGHER,
  high: StatusMarkerType.HIGH,
  "critical-high": StatusMarkerType.CRITICAL_HIGH,
};
export const StatusMarkerClass: { [key in StatusMarkerType]: string } = {
  criticalLow: "critical-low",
  low: "low",
  lower: "lower",
  optimal: "optimal",
  higher: "higher",
  high: "high",
  criticalHigh: "critical-high",
};

export interface BloodTests {
  totalCount: number | null;
  results: BloodTestShort[];
}

export interface Laboratory {
  country: string;
  name: string;
}

export interface BloodTestShort {
  id: number;
  type: string;
  state: "draft" | "published";
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  laboratory: Laboratory | null;
  markers: Marker[];
}

export interface BloodTestFull {
  id: number;
  type: string;
  state: "draft" | "published";
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  laboratory: Laboratory;
  bloodMarkerResults: MarkerShort[];
  healthAdvices: {
    medical: HealthAdviceMedical[];
    nutritional: HealthAdviceNutritional[];
  };
}

export interface MarkerShort {
  id: number;
  bloodMarker: {
    id: number;
    name: string;
  };
  value: string;
  unit: string;
  range: StatusMarkerTypeKebabCase;
  referenceValues: { [key in StatusMarkerType]: { min: string | null; max: string | null } };
}

export interface Marker {
  id: number;
  bloodMarker: {
    id: number;
    name: string;
    description: string;
    lowLevelSymptoms: string;
    highLevelSymptoms: string;
  };
  value: string;
  unit: string;
  range: StatusMarkerTypeKebabCase;
  referenceValues: any;
  previousResults: {
    date: string;
    value: number;
    number: number;
  }[];
}

export interface HealthAdviceMedical {
  id: number;
  key: string;
  description: string;
  type: string;
  adviceGroups: string[];
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface HealthAdviceNutritional {
  id: string;
  key: string;
  description: string;
  type: string;
  adviceGroups: string[];
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface BloodTestResults {
  [key: number]: BloodTestFull;
}

export const useCheckupResultsStore = defineStore("checkupResultsStore", () => {
  const bloodTests = ref<{ totalCount: number | null; results: BloodTestShort[] }>({
    totalCount: null,
    results: [],
  });
  const bloodTestResults = ref<BloodTestResults>({});
  const marker = ref<Marker | null>(null);
  const bloodTestUploadCredits = ref<number | null>(0);

  const fetchBloodTests = async (): Promise<[boolean, BloodTests | null]> => {
    if (bloodTests.value.totalCount !== null) return [false, bloodTests.value];
    try {
      const res = await axiosInstance.get(`${API.BLOOD.RESULTS}?filters[state_eq]=published`);
      const data: BloodTests = camelcaseKeys(res.data, { deep: true });
      bloodTests.value.totalCount = data.totalCount;
      bloodTests.value.results = data.results;
      return [false, data];
    } catch (err) {
      console.error("Error on GET /blood-results API, ", err);
      return [true, null];
    }
  };

  const fetchBloodTest = async (bloodTestId: number): Promise<[boolean, BloodTestFull | null]> => {
    const btr = bloodTestResults.value[bloodTestId];
    if (btr) return [false, btr];
    try {
      const res = await axiosInstance.get(API.BLOOD.RESULT.replace("{id}", bloodTestId.toString()));
      const data: BloodTestFull = camelcaseKeys(res.data, { deep: true });
      bloodTestResults.value[bloodTestId] = data;
      return [false, data];
    } catch (err) {
      console.error(`Error on GET /blood-tests/results/${bloodTestId} `, err);
      return [true, null];
    }
  };

  const fetchMarker = async (markerId: number, bloodTestId: number): Promise<[boolean, Marker | null]> => {
    if (markerId === marker.value?.id) return [false, marker.value];

    try {
      const res = await axiosInstance.get(
        API.BLOOD.MARKER.replace("{blood_test_id}", bloodTestId.toString()).replace(
          "{blood_marker_result_id}",
          markerId.toString()
        )
      );
      const data: Marker = camelcaseKeys(res.data, { deep: true });
      marker.value = data;
      return [false, data];
    } catch (err) {
      console.error("Error on GET /marker API, ", err);
      return [true, null];
    }
  };

  const uploadBloodTest = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axiosInstance.post(
        API.BLOOD_TEST_UPLOAD,
        { document: file },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return [false, res];
    } catch (err) {
      console.error("Error on POST /blood_tests/upload API, ", err);
      return [true, null];
    }
  };

  const getBloodTestsUploadCredits = async (): Promise<[null | true, null | number]> => {
    try {
      const res = await axiosInstance.get(API.BLOOD_TEST_UPLOAD_CREDITS);
      const data: number = camelcaseKeys(res.data).bloodTestUploadCredits;
      bloodTestUploadCredits.value = data;
      return [null, data];
    } catch (err) {
      console.error("Error fetching the remaining upload credits, ", err);
      bloodTestUploadCredits.value = null;
      return [true, null];
    }
  };

  return {
    // State
    bloodTests,
    bloodTestResults,
    marker,
    bloodTestUploadCredits,
    // Actions
    fetchBloodTests,
    fetchBloodTest,
    fetchMarker,
    uploadBloodTest,
    getBloodTestsUploadCredits,
  };
});
