import { APP_STORE_URL } from "@/util/constants";
import { HeightImperialUnit } from "@/types/MemberTypes";

export function goToVahaWebsite() {
  window.location.href = "https://vaha.com";
}

export function goToAndroidStore() {
  window.location.href = APP_STORE_URL.android;
}

export function goToiOSStore() {
  window.location.href = APP_STORE_URL.ios;
}

export function isMobileBrowser() {
  return /Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent);
}

export function isIOSMobileBrowser() {
  return /iPhone/i.test(navigator.userAgent) || (/Macintosh/.test(navigator.userAgent) && "ontouchend" in document);
}

export function isMacOS() {
  return navigator.platform.indexOf("Mac") > -1;
}

function isDateToday(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function formatDate(dateString: string, t: any) {
  const date = new Date(dateString);
  if (isDateToday(date)) return t("DATE.TODAY");
  return date.toLocaleDateString("default", { weekday: "short", day: "numeric", month: "short" }).replace(",", "");
}

export function transformInchesToCm(val: string): string {
  if (!val) return "";
  return Math.floor(parseFloat(val) * 2.54).toString();
}

export function transformCmToInches(val: string): string {
  if (!val) return "";
  return Math.floor(parseFloat(val) / 2.54).toString();
}

export function transformCmToFeetInches(val: string): { [key in HeightImperialUnit]: string } {
  if (!val) return { feet: "", inches: "" };

  const totalInches = parseFloat(val) / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = Math.floor(totalInches - feet * 12);
  return { feet: feet.toString(), inches: inches.toString() };
}

export function transformFeetInchesToCm(val: { [key in HeightImperialUnit]: string }): string {
  const { feet, inches } = val;
  if (!feet || !inches) return "";

  const inchesFromFeet = parseFloat(feet) * 12;
  const totalInches = inchesFromFeet + parseFloat(inches);
  return Math.floor(totalInches * 2.54).toString();
}

export function transformFeetInchesToInches(val: { [key in HeightImperialUnit]: string }): string {
  const { feet, inches } = val;
  if (!feet || !inches) return "";

  return (parseFloat(feet) * 12 + parseFloat(inches)).toString();
}

export function transformInchesToFeetInches(val: string): { [key in HeightImperialUnit]: string } {
  if (!val) return { feet: "", inches: "" };

  const valNum = parseFloat(val);
  const feet = Math.floor(valNum / 12);
  const inches = Math.round(valNum - feet * 12);
  return { feet: feet.toString(), inches: inches.toString() };
}

export function transformPoundsToKg(val: string): string {
  return Math.round(parseFloat(val) / 2.2046).toString();
}

export function transformKgToPounds(val: string): string {
  return Math.round(parseFloat(val) * 2.2046).toString();
}

export function isLimitedAppFunctionality(): boolean {
  return import.meta.env.VITE_NODE_ENV !== "development";
}
