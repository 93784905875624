<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useToastMsg } from "@/composables/useToastMsg";
import { useRoute, useRouter } from "vue-router";
import { RouteActions } from "@/types/AuthTypes";
import ModalMain from "@/components/ui/ModalMain.vue";
import UploadBloodTestModalContent from "@/components/blood-drop/UploadBloodTestModalContent.vue";
import { isLimitedAppFunctionality } from "@/util/functions";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

// ----- Plus Action -----
const plusIcon = ref<HTMLElement | null>(null);
const showActionsContainer = ref(false);

const openActionsContainer = () => {
  showActionsContainer.value = true;
};

const closeActionsContainer = () => {
  if (!showActionsContainer.value) return;
  showActionsContainer.value = false;
};
// ----- Plus Action -----

// ----- Modal -----
const modalOpened = ref(false);

const openUploadBloodTestModal = (e?: Event) => {
  modalOpened.value = true;
  if (e) e.stopPropagation();
  router.push({ query: { action: RouteActions.UPLOAD_BLOOD_TEST } });
};
const closeUploadBloodTestModal = () => {
  if (isLimitedAppFunctionality()) return;

  modalOpened.value = false;
  router.push({ query: {} });
};
// ----- Modal -----

// ----- Toast Notifications -----
const { showMessage: showToastMessage } = useToastMsg();

const handleUploadFinished = (err: boolean) => {
  closeUploadBloodTestModal();

  if (err) return showToastMessage({ msg: t("TOAST.ERROR.SOMETHING_WENT_WRONG"), type: "error" });
  return showToastMessage({ msg: t("TOAST.SUCCESS.ANALYTICS.BLOOD_TEST_UPLOAD"), type: "success" });
};
// ----- Toast Notifications -----

// // ----- Route Upload Action -----
const prepareUploadFromRoute = async () => {
  openActionsContainer();
  openUploadBloodTestModal();
};

watch(
  route,
  (val: any) => {
    if (val.query.action === RouteActions.UPLOAD_BLOOD_TEST) prepareUploadFromRoute();
  },
  { immediate: true }
);
// // ----- Route Upload Action -----
</script>

<template>
  <div class="upload-blood-container" v-show="!isLimitedAppFunctionality()">
    <div class="actions-container" v-if="showActionsContainer" v-click-outside:[plusIcon]="closeActionsContainer">
      <div class="title">{{ t("SECTION.BLOOD_DROP.ACTIONS.SELECT_OPT") }}</div>
      <div class="option" @click="openUploadBloodTestModal">
        <div class="icon"><img src="@/assets/icons/upload-blue.svg" alt="upload-action" /></div>
        <div class="text">{{ t("SECTION.BLOOD_DROP.ACTIONS.UPLOAD_TEST") }}</div>
      </div>
    </div>

    <div class="upload-plus-img" ref="plusIcon" @click="openActionsContainer">
      <img src="@/assets/icons/plus-blue.svg" />
    </div>
  </div>

  <ModalMain v-if="modalOpened" @clickOutside="closeUploadBloodTestModal">
    <UploadBloodTestModalContent @uploadFinished="handleUploadFinished" />
  </ModalMain>
</template>

<style scoped lang="pcss">
.upload-blood-container {
  width: 100%;
  z-index: 1;
}
.actions-container {
  border-radius: 1em;
  box-shadow: 0px 0px 20px -3px rgba(0,0,0,0.2);
  max-width: 20em;
  position:fixed;
  bottom: 7em;
  width: 100%;
  margin-bottom: 1em;
  background-color: var(--clr-white-1);
  & .title {
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0 0 1em 1em;
    padding-top: 1em;
  }
  & .option {
    display: flex;
    gap: 0.5em;
    align-items: center;
    cursor: pointer;
    padding: 1em;
    & .icon {
      width: 1.5em;
      height: 1.5em;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    & .text {
      font-size: 1rem;
    }
  }
  & .option:hover {
    background-color: var(--clr-grey-1);
  }
}
.upload-plus-img {
  width: 5em;
  height: 5em;
  cursor: pointer;
  position: fixed;
  bottom: 2em;
  & img {
    width: 100%;
    height: 100%;
  }
}
</style>
