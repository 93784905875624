import { ref } from "vue";
import { defineStore } from "pinia";
import { API } from "@/util/api/api_paths";
import { axiosInstance } from "@/util/api/axios_instance";
import camelcaseKeys from "camelcase-keys";

export interface Supplement {
  supplement: {
    name: string;
    key: string;
  };
  dosage: number;
  basicDose: number;
  finalDose: number;
}

export interface Ingredient {
  ingredient: {
    name: string;
    key: string;
  };
  amountPerServing: number;
  unit: string;
  nrvPercent: string;
}

export interface Formulas {
  totalCount: number | null;
  supplementFormulas: SupplementFormulaShort[];
}

export enum SupplemenFormulaStatus {
  READY = "ready",
  APPROVED = "approved_for_production",
}
export enum SupplemenFormulaProductType {
  GO = "go",
  PRO = "pro",
}

export interface SupplementFormulaShort {
  id: number;
  recipePdf: string;
  status: SupplemenFormulaStatus;
  productType: SupplemenFormulaProductType;
  createdAt: string;
}

export interface SupplementFormulaFull {
  id: number;
  recipePdf: string;
  status: SupplemenFormulaStatus;
  productType: SupplemenFormulaProductType;
  createdAt: string;
  jarSize: number;
  supplements: Supplement[];
  ingredients: Ingredient[];
}

export interface FormulasComplete {
  [key: number]: SupplementFormulaFull;
}

export const useFormulasStore = defineStore("formulaStore", () => {
  const formulas = ref<{ totalCount: number | null; supplementFormulas: SupplementFormulaShort[] }>({
    totalCount: null,
    supplementFormulas: [],
  });
  const formulasComplete = ref<FormulasComplete>({});

  const fetchFormulas = async (): Promise<[boolean, Formulas | null]> => {
    if (formulas.value.totalCount !== null) return [false, formulas.value];
    try {
      const res = await axiosInstance.get(API.SUPPLEMENT_FORMULAS);
      const data: Formulas = camelcaseKeys(res.data, { deep: true });
      formulas.value.totalCount = data.totalCount;
      formulas.value.supplementFormulas = data.supplementFormulas;
      return [false, data];
    } catch (err) {
      console.error("Error on GET /supplement_formulas API, ", err);
      return [true, null];
    }
  };

  const fetchFormulaComplete = async (formulaId: number): Promise<[boolean, SupplementFormulaFull | null]> => {
    const existingFormula = formulasComplete.value[formulaId];
    if (existingFormula) return [false, existingFormula];

    try {
      const res = await axiosInstance.get(API.SUPPLEMENT_FORMULA.replace("{id}", formulaId.toString()));
      const formula: SupplementFormulaFull = camelcaseKeys(res.data, { deep: true });
      formulasComplete.value[formulaId] = formula;
      return [false, formula];
    } catch (err) {
      console.error(`Error on GET /supplement-formula/${formulaId} `, err);
      return [true, null];
    }
  };

  return {
    // State
    formulas,
    formulasComplete,
    // Actions
    fetchFormulas,
    fetchFormulaComplete,
  };
});
