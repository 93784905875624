import { ref, onUnmounted } from "vue";
import { emitter, EmmitEvents } from "@/includes/emmiter";

export enum TopRowSlotButtons {
  BUTTON_ONE = "row-button-one",
  BUTTON_TWO = "row-button-two",
}

export function useToggleableListItemMainEvents(vueEmit: any) {
  // ----- Top Row Slot Buttons and Events-----
  const topRowButtons = ref<{ [key in TopRowSlotButtons]?: boolean }>({
    [TopRowSlotButtons.BUTTON_ONE]: false,
  });

  const clickRowButtonOne = (event: MouseEvent) => {
    event.stopPropagation();

    topRowButtons.value[TopRowSlotButtons.BUTTON_ONE] = true;
    emitter.emit(EmmitEvents.LOADING_TOGGL_LIST_ITEM_MAIN_BTN_ONE);
    vueEmit("rowButtonOneClicked");
  };

  const clickRowButtonTwo = (event: MouseEvent) => {
    event.stopPropagation();

    topRowButtons.value[TopRowSlotButtons.BUTTON_TWO] = true;
    emitter.emit(EmmitEvents.LOADING_TOGGL_LIST_ITEM_MAIN_BTN_TWO);
    vueEmit("rowButtonTwoClicked");
  };

  const addTopRowButtonsListeners = () => {
    emitter.on(
      EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_ONE,
      resetTopRowButtons.bind(null, TopRowSlotButtons.BUTTON_ONE)
    );
    emitter.on(
      EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_TWO,
      resetTopRowButtons.bind(null, TopRowSlotButtons.BUTTON_TWO)
    );
  };

  const removeTopRowButtonsListeners = () => {
    emitter.off(
      EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_ONE,
      resetTopRowButtons.bind(null, TopRowSlotButtons.BUTTON_ONE)
    );
    emitter.off(
      EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_TWO,
      resetTopRowButtons.bind(null, TopRowSlotButtons.BUTTON_TWO)
    );
  };

  const resetTopRowButtons = (btn: TopRowSlotButtons) => {
    topRowButtons.value[btn] = false;
  };

  addTopRowButtonsListeners();
  onUnmounted(() => removeTopRowButtonsListeners());
  // ----- Top Row Slot Buttons and Events -----

  return { topRowButtons, clickRowButtonOne, clickRowButtonTwo };
}
