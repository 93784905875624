import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { API } from "@/util/api/api_paths";
import { axiosInstance } from "@/util/api/axios_instance";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { RouteName, RouteNameMenu } from "@/types/AuthTypes";
import { useMemberStore } from "@/stores/member";
import { i18n } from "@/includes/i18n-setup";
import { GenderAnswers, ProfileMandatoryQuestions, GenderIds } from "@/util/data";
import { ProfileInfoMandatoryKeys } from "@/util/constants";
import type { QuestionType } from "@/types/QuestionnaireTypes";
import { Gender } from "@/types/MemberTypes";
import { useRouter } from "vue-router";

export enum QuestionnairesKey {
  SUPPLEMENTS = "test_radu_supplements",
  LIFESTYLE = "lifestyle_questionnaire",
  ONBOARDING = "onboarding_chatbot",
  FIRST_CLASS = "first_classes_recommendation_onboarding",
  ALGORITHM = "bioniq-algorithm-questionnaire",
}

export interface QuestionAnswer {
  id: number;
  negativeAnswer: boolean;
  predefinedValue: string | null;
  questionId: number;
  value: any;
}

export interface Question {
  availableAnswers: QuestionAnswer[];
  hasMultipleOptions: boolean;
  hasNoneOfTheAboveOption: boolean;
  hasOtherOption: any;
  id: number;
  key: string;
  maxNumberOfOptions: any;
  minNumberOfOptions: any;
  questionBody: string;
  questionType: QuestionType;
  mandatory: boolean;
}

export interface Questionnaire {
  key: QuestionnairesKey;
  name: string;
  state: "published";
  questions: Question[];
}

//* QuestionComplexType is used STRICTLY for Frontend
export enum QuestionComplexType {
  TEXT_INPUT = "text-input", // * This is used for profile info mandatory questions
  TEXTAREA = "textarea",
  CHECKBOX = "checkbox",
  RADIO = "radio",

  BIRTHDAY = "birthday", // * This is mockup for profile info mandatory questions
  PHONE_NUMBER = "phone-number", // * This is mockup for profile info mandatory questions
  HEIGHT = "height", // * This is mockup for profile info mandatory questions
  WEIGHT = "weight", // * This is mockup for profile info mandatory questions
  AVATAR = "avatar",

  RADIO_WITH_OTHER = "radio-with-other", // * This is for Radio questions with hasOtherOption free input textfield
}

export type AnswerValue = { value: string | null; answerId: number | null; prefixForOtherOptionVal?: string };

export interface Answer {
  type: QuestionComplexType;
  value: AnswerValue[] | AnswerValue;
  error: boolean;
}

export interface QuestionnaireAnswersPayload {
  questionId: number;
  availableAnswerId: number | null;
  freeAnswerValue: string | null;
}

export interface QuestionnaireAnswers {
  id: number;
  questionId: number;
  memberId: number;
  availableAnswerId: number;
  freeAnswerValue: string;
}

export const mandatoryQuestionnaireKeys: QuestionnairesKey[] = [QuestionnairesKey.ALGORITHM];

// TODO update to all keys
export const questMapper: { [key in QuestionnairesKey]?: { name: RouteName; params: { nr: number } } } = {
  test_radu_supplements: { name: RouteName.QUESTIONNAIRE_SUPPLEMENTS, params: { nr: 1 } },
  lifestyle_questionnaire: { name: RouteName.QUESTIONNAIRE_LIFESTYLE, params: { nr: 1 } },
  onboarding_chatbot: { name: RouteName.QUESTIONNAIRE_ONBOARDING_CHATBOT, params: { nr: 1 } },
  first_classes_recommendation_onboarding: { name: RouteName.QUESTIONNAIRE_FIRSTCLASSES_REC, params: { nr: 1 } },
  "bioniq-algorithm-questionnaire": { name: RouteName.QUESTIONNAIRE_ALGORITHM, params: { nr: 1 } },
};

export const useQuestionnairesStore = defineStore("questionnairesStore", () => {
  const questionnaires = ref<Questionnaire[] | null>(null);
  const answers = ref<{ [key in QuestionnairesKey]: QuestionnaireAnswers[] }>({
    [QuestionnairesKey.SUPPLEMENTS]: [],
    [QuestionnairesKey.LIFESTYLE]: [],
    [QuestionnairesKey.ONBOARDING]: [],
    [QuestionnairesKey.FIRST_CLASS]: [],
    [QuestionnairesKey.ALGORITHM]: [],
  });
  const activeQuestionnaireKey = ref<QuestionnairesKey | null>(null);
  const erroredSteps = ref<boolean[]>([]);
  const isMandQuestWithProfileQuestions = ref<boolean>(false);

  const supplementsQuestionnaire = computed<Questionnaire | null>(() => {
    if (!questionnaires.value) return null;

    const supplQuest = questionnaires.value.find((quest: Questionnaire) => quest.key === QuestionnairesKey.SUPPLEMENTS);
    return supplQuest || null;
  });

  const supplementsAnswers = computed<QuestionnaireAnswers[]>(() => {
    return answers.value[QuestionnairesKey.SUPPLEMENTS];
  });

  const activeQuestionnaire = computed<Questionnaire | null>(() => {
    if (!questionnaires.value) return null;
    return questionnaires.value.find((quest: Questionnaire) => quest.key === activeQuestionnaireKey.value) || null;
  });

  const isMandatoryProfileInfoCompleted = computed<boolean>(() => {
    const memberStore = useMemberStore();
    return memberStore.isMandatoryProfileInfoCompleted;
  });

  const setIsMandQuestWithProfileQuestions = (val: boolean) => {
    isMandQuestWithProfileQuestions.value = val;
  };

  const pushMandatoryProfileInfoQuestionsToActiveQuestionnaire = () => {
    setIsMandQuestWithProfileQuestions(true);

    [...Object.values(ProfileInfoMandatoryKeys).reverse()].forEach((value) => {
      activeQuestionnaire.value?.questions.unshift(ProfileMandatoryQuestions[value]);
    });
  };

  const fetchQuestionnaireByKey = async (keys: QuestionnairesKey[]) => {
    try {
      const res = await axiosInstance.get(API.QUESTIONNAIRE_BY_KEY, { params: { keys } });
      const data = camelcaseKeys(res.data.questionnaires, { deep: true });
      if (!activeQuestionnaireKey.value) activeQuestionnaireKey.value = data[0].key;
      questionnaires.value = data;
      return [false, true];
    } catch (err) {
      console.error("Error calling the /questionnaires/by_key API, ", err);
      return [true, false];
    }
  };

  const updateErrorSteps = (steps: boolean[]) => {
    erroredSteps.value = steps;
  };

  const postQuestionnaireAnswers = async (
    questionnaireKey: QuestionnairesKey,
    questAnswers: Record<number, Answer>
  ) => {
    const payload: QuestionnaireAnswersPayload[] = [];

    for (const [key, value] of Object.entries(questAnswers)) {
      const questionId = activeQuestionnaire.value?.questions[parseInt(key) - 1].id as number;
      if (value.type === QuestionComplexType.TEXTAREA) {
        payload.push({
          questionId,
          availableAnswerId: null,
          freeAnswerValue: (value.value as AnswerValue).value,
        });
      } else if (value.type === QuestionComplexType.RADIO) {
        if (!(value.value as AnswerValue).answerId) continue;
        payload.push({
          questionId,
          availableAnswerId: (value.value as AnswerValue).answerId,
          freeAnswerValue: null,
        });
      } else if (value.type === QuestionComplexType.CHECKBOX) {
        if (!(value.value as AnswerValue[]).length) continue;

        const spreadedAnswers = (value.value as AnswerValue[]).map((ansVal) => {
          return { availableAnswerId: ansVal.answerId as number, questionId, freeAnswerValue: null };
        });
        payload.push(...spreadedAnswers);
      } else if (value.type === QuestionComplexType.RADIO_WITH_OTHER) {
        if ((value.value as AnswerValue).answerId) {
          payload.push({
            questionId,
            availableAnswerId: (value.value as AnswerValue).answerId,
            freeAnswerValue: null,
          });
        } else {
          payload.push({
            questionId,
            availableAnswerId: null,
            freeAnswerValue: (value.value as AnswerValue).value,
          });
        }
      }
    }
    try {
      const res = await axiosInstance.post(API.QUESTIONNAIRE_ANSWERS, { answers: snakecaseKeys(payload) });
      const data = camelcaseKeys(res.data, { deep: true });
      answers.value[questionnaireKey] = data.memberAnswers;
      return [false, true];
    } catch (err) {
      console.error("Error calling the POST /questionnaire_answers API, ", err);
      return [true, false];
    }
  };

  const getQuestionnaireAnswers = async (key: QuestionnairesKey) => {
    try {
      const res = await axiosInstance.get(API.QUESTIONNAIRE_ANSWERS, { params: { questionnaire_keys: [key] } });
      const data: QuestionnaireAnswers[] = camelcaseKeys(res.data.member_answers, { deep: true });
      if (!data) return [true, false];

      answers.value[key] = data;
      return [false, true];
    } catch (err) {
      console.error("Error calling the GET /questionnaire_answers API", err);
    }
  };

  const computeValueForUIComponentFromAnswers = (
    questionnaireKey: QuestionnairesKey,
    question: Question,
    questionType: QuestionComplexType
  ): AnswerValue | AnswerValue[] => {
    // TODO this whole conditional logic based on QuestionComplexType is used in store and QuestionnaireSupplements file
    // TODO and needs some refactoring, a type pattern using a closure to encapsulate the conditional... Will have to think about it

    if (questionType === QuestionComplexType.TEXTAREA || questionType === QuestionComplexType.TEXT_INPUT) {
      const answerAlreadyExistForThisQuest = answers.value[questionnaireKey].find(
        (answer) => answer.questionId === question.id
      );
      const value: AnswerValue = { answerId: null, value: "" };
      if (!answerAlreadyExistForThisQuest) return value;

      value.value = answerAlreadyExistForThisQuest.freeAnswerValue;
      return value;
    } else if (questionType === QuestionComplexType.RADIO) {
      let answerAlreadyExistForThisQuest: QuestionnaireAnswers | undefined;

      // * ----- Mimicking answer entities for Profile Mandatory Gender Question so in the case there is an initial selection, it is selected (although this should never be the case realistically, but I like to make things complicated for no fucking reason at all 🙃)
      // TODO this should be moved somewhere else
      const memberStore = useMemberStore();
      if (question.key === ProfileInfoMandatoryKeys.GENDER) {
        if (memberStore.profile?.account.gender === Gender.MALE) {
          answerAlreadyExistForThisQuest = GenderAnswers.find(
            (answer) => answer.availableAnswerId === GenderIds.answerMale
          );
        } else if (memberStore.profile?.account.gender === Gender.FEMALE) {
          answerAlreadyExistForThisQuest = GenderAnswers.find(
            (answer) => answer.availableAnswerId === GenderIds.answerFemale
          );
        } else if (memberStore.profile?.account.gender === Gender.CRAZY) {
          answerAlreadyExistForThisQuest = GenderAnswers.find(
            (answer) => answer.availableAnswerId === GenderIds.answerNon
          );
        }
      } else {
        answerAlreadyExistForThisQuest = answers.value[questionnaireKey].find(
          (answer) => answer.questionId === question.id
        );
      }
      // * -----

      const value: AnswerValue = { answerId: null, value: "" };

      if (!answerAlreadyExistForThisQuest) return value;

      value.answerId = answerAlreadyExistForThisQuest.availableAnswerId;
      const questionAnswer = question.availableAnswers.find(
        (aa) => aa.id === answerAlreadyExistForThisQuest?.availableAnswerId
      );

      value.value = questionAnswer?.predefinedValue || null;
      return value;
    } else if (questionType === QuestionComplexType.CHECKBOX) {
      const answersAlreadyExistForThisQuest = answers.value[questionnaireKey].filter(
        (answer) => answer.questionId === question.id
      );
      const value: AnswerValue[] = [];
      if (!answersAlreadyExistForThisQuest.length) return value;

      question.availableAnswers.forEach((aa) => {
        const aaAnswerPresent = answersAlreadyExistForThisQuest.some((answer) => answer.availableAnswerId === aa.id);
        if (aaAnswerPresent) {
          // @ts-ignore
          const name = aa.value ? aa.value.translations[i18n.global.locale.value].name : aa.predefinedValue;
          value.push({ answerId: aa.id, value: name });
        }
      });
      return value;
    } else if (questionType === QuestionComplexType.RADIO_WITH_OTHER) {
      const value: AnswerValue = { answerId: null, value: "" };
      const answerAlreadyExistForThisQuest = answers.value[questionnaireKey].find(
        (answer) => answer.questionId === question.id
      );
      if (!answerAlreadyExistForThisQuest) return value;

      if (!answerAlreadyExistForThisQuest.availableAnswerId && !!answerAlreadyExistForThisQuest.freeAnswerValue) {
        // * This means user inputted custom message in that free text input field
        value.value = answerAlreadyExistForThisQuest.freeAnswerValue;
        value.prefixForOtherOptionVal = question.hasOtherOption;
        return value;
      } else {
        // * This means he preselected an option
        const questionAnswer = question.availableAnswers.find(
          (aa) => aa.id === answerAlreadyExistForThisQuest.availableAnswerId
        );
        value.answerId = answerAlreadyExistForThisQuest.availableAnswerId;
        value.value = questionAnswer?.predefinedValue || "";
        return value;
      }
    }
    return [];
  };

  const questionnaireFinished = async () => {
    const router = useRouter();

    setIsMandQuestWithProfileQuestions(false);
    await useMemberStore().fetchMemberProfile();
    router.push({ name: RouteNameMenu.MENU_ANALYTICS, query: { ...router.currentRoute.value.query } });
  };

  return {
    // State
    questionnaires,
    answers,
    activeQuestionnaireKey,
    erroredSteps,
    isMandQuestWithProfileQuestions,
    // Computed
    supplementsQuestionnaire,
    supplementsAnswers,
    activeQuestionnaire,
    isMandatoryProfileInfoCompleted,
    // Actions
    setIsMandQuestWithProfileQuestions,
    pushMandatoryProfileInfoQuestionsToActiveQuestionnaire,
    fetchQuestionnaireByKey,
    updateErrorSteps,
    postQuestionnaireAnswers,
    getQuestionnaireAnswers,
    computeValueForUIComponentFromAnswers,
    questionnaireFinished,
  };
});
