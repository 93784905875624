<script setup lang="ts">
import { toRef } from "vue";
import { useTextInput } from "@/composables/ui/useTextInput";

const props = defineProps<{
  inputName: string;
  value: string;
  labelText: string;
  errored?: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits<{ (e: "update", code: string): void }>();

const { value, focused, emitUpdate, focusInput } = useTextInput(toRef(props, "value"), emit);
</script>

<template>
  <div class="text-input">
    <label :for="inputName" :class="{ 'moved-up': focused, error: errored, disabled: disabled }">
      {{ labelText }}
    </label>
    <input
      type="text"
      :class="{ error: errored }"
      :name="inputName"
      :value="value"
      @input="emitUpdate"
      @focus="focusInput"
      @blur="focusInput"
      :disabled="disabled"
    />
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/inputs.css";

@mixin inputLine;
</style>
