import { defineStore } from "pinia";
import { ref } from "vue";

declare module "pinia" {
  export interface PiniaCustomProperties {
    router: any;
  }
}

export enum DownloadMobileAppTextKey {
  DOWNLOAD = "DOWNLOAD",
  UPLOAD_BT = "UPLOAD_BT",
}

export const useMainStore = defineStore("mainStore", () => {
  const appLoading = ref<boolean>(false);
  const downloadMobileAppTextKey = ref<DownloadMobileAppTextKey>(DownloadMobileAppTextKey.DOWNLOAD);

  const changeDownloadMobileAppTextKey = (key: DownloadMobileAppTextKey) => {
    downloadMobileAppTextKey.value = key;
  };

  return {
    // State
    appLoading,
    downloadMobileAppTextKey,
    // Actions
    changeDownloadMobileAppTextKey,
  };
});
