export const MEMBERS_BASE_PATH = `${import.meta.env.VITE_API_HOST}/members/api/v1`;

export const API = {
  SIGN_IN: `${MEMBERS_BASE_PATH}/sign_in`,
  SIGN_IN_CONFIRM: `${MEMBERS_BASE_PATH}/sign_in/confirm`,
  CHECK_EMAIL: `${MEMBERS_BASE_PATH}/check_email`,
  REQ_RESET: `${MEMBERS_BASE_PATH}/password/request_reset`,
  CONFIRM_RESET: `${MEMBERS_BASE_PATH}/password/confirm_reset`,
  SIGN_UP_CONFIRM: `${MEMBERS_BASE_PATH}/sign_up/confirm`,
  SIGN_UP: `${MEMBERS_BASE_PATH}/sign_up`,
  REQ_EMAIL_RESET: `${MEMBERS_BASE_PATH}/email`,
  CONFIRM_EMAIL_CHANGE: `${MEMBERS_BASE_PATH}/email/confirm`,
  MEMBER_PROFILE: `${MEMBERS_BASE_PATH}/profile`,
  QUESTIONNAIRE_BY_KEY: `${MEMBERS_BASE_PATH}/questionnaires/by_key`,
  QUESTIONNAIRE_ANSWERS: `${MEMBERS_BASE_PATH}/questionnaire_answers`,
  MESSAGES: `${MEMBERS_BASE_PATH}/received_messages`,
  MESSAGES_MARK_ALL_READ: `${MEMBERS_BASE_PATH}/received_messages/mark_as_read`,
  BLOOD: {
    RESULTS: `${MEMBERS_BASE_PATH}/blood_tests/results`,
    RESULT: `${MEMBERS_BASE_PATH}/blood_tests/results/{id}`,
    MARKER: `${MEMBERS_BASE_PATH}/blood_tests/results/{blood_test_id}/blood_marker_results/{blood_marker_result_id}`,
  },
  SUPPLEMENT_FORMULAS: `${MEMBERS_BASE_PATH}/supplement_formulas`,
  SUPPLEMENT_FORMULA: `${MEMBERS_BASE_PATH}/supplement_formulas/{id}`,
  BLOOD_TEST_UPLOAD: `${MEMBERS_BASE_PATH}/blood_tests/upload`,
  BLOOD_TEST_UPLOAD_CREDITS: `${MEMBERS_BASE_PATH}/blood_tests/upload_credits`,
};
