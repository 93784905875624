import type { InjectionKey } from "vue";
import mitt, { type Emitter } from "mitt";

enum EmmitEvents {
  EXPORT_FORMULA_PDF = "EXPORT_FORMULA_PDF",
  LOADING_TOGGL_LIST_ITEM_MAIN_BTN_ONE = "LOADING_TOGGL_LIST_ITEM_MAIN_BTN_ONE", // * event for this Component: "@/components/ui/ToggleableListItemMain.vue"
  LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_ONE = "LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_ONE", // * event for this Component: "@/components/ui/ToggleableListItemMain.vue"
  LOADING_TOGGL_LIST_ITEM_MAIN_BTN_TWO = "LOADING_TOGGL_LIST_ITEM_MAIN_BTN_TWO", // * event for this Component: "@/components/ui/ToggleableListItemMain.vue"
  LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_TWO = "LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_TWO", // * event for this Component: "@/components/ui/ToggleableListItemMain.vue"
}

type EmmitEventsType = {
  [EmmitEvents.EXPORT_FORMULA_PDF]: number;
  [EmmitEvents.LOADING_TOGGL_LIST_ITEM_MAIN_BTN_ONE]: void;
  [EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_ONE]: void;
  [EmmitEvents.LOADING_TOGGL_LIST_ITEM_MAIN_BTN_TWO]: void;
  [EmmitEvents.LOADING_DONE_TOGGL_LIST_ITEM_MAIN_BTN_TWO]: void;
};

const injectionKey = Symbol() as InjectionKey<Emitter<EmmitEventsType>>;

const emitter: Emitter<EmmitEventsType> = mitt();

export { type EmmitEventsType, EmmitEvents, injectionKey, emitter };
