import { LOCALE, Currency } from "@/util/constants";
import { QuestionnairesKey } from "@/stores/questionnaires";

// ----- Helper Types -----
export type PhoneNumberData = {
  value: string;
  countryCode: string;
  isValid: boolean;
  e164: string;
};

export enum HeightImperialUnit {
  FEET = "feet",
  INCHES = "inches",
}
// ----- Helper Types -----

export enum Gender {
  MALE = "male",
  FEMALE = "female",
  CRAZY = "", // ! This will actually be null from BE
}

export interface MemberAccount {
  email: string;
  firstName: string;
  lastName: string;
  gender: Gender;
}

export enum WeightUnit {
  POUNDS = "lbs",
  KG = "kg",
}
export enum HeightUnit {
  INCHES = "inches",
  CM = "cm",
}

export interface MemberAvatar {
  originalUrl: string;
  thumbUrl: string;
}

export interface CallPackage {
  id: number;
  purchasedCalls: number;
  usedCalls: number;
  callsLeft: number;
  expirationDate: string;
  active: boolean;
}

export interface CustomGoal {
  id: number;
  name: string;
}

export interface Goal extends CustomGoal {
  description: string;
}

export type MemberType = "customer";
export type NpsQuestionCategory = "product";

export type PreferredWorkoutDay = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
export type MemberVisibility = "publicly_available";

export interface SubscriptionType {
  currency: Currency;
  description: string;
  includedCalls: number;
  includedGroupCalls: number;
  key: string;
  name: string;
  price: number;
  provider: "loopwork";
}

export type MemberSubscriptionState = "ACTIVE";

export interface MemberSubscription {
  callsLeft: number;
  cancellationScheduledAt: string | null;
  id: number;
  pausable: boolean;
  pauseEndTime: string | null;
  pauseStartTime: string | null;
  paused: boolean;
  paymentPageUrl: string | null;
  periodResetDate: string;
  provider: "loopwork";
  scheduledChangeDate: string | null;
  scheduledChangedType: number | null;
  state: MemberSubscriptionState;
  subscriptionType: SubscriptionType;
  usedCalls: number;
  usedGroupCalls: number;
}

export interface ReferralCode {
  id: number;
  code: string;
  member_id: number;
  created_at: string;
  updated_at: string;
  reset_successful_referrals_date: null;
  successful_referrals_count: number;
}

export interface MemberProfile {
  acceptedTermsAndConditionsKeys: (string | null)[];
  account: MemberAccount;
  areasOfInterest: string[]; // TODO revisit type
  availableLanguages: LOCALE[];
  avatar: MemberAvatar | null;
  birthday: string;
  bloodTestUploadCredits: number;
  bodyMassIndex: number;
  callPackages: CallPackage[];
  contentLocale: LOCALE;
  countryCode: string;
  customGoals: CustomGoal[];
  equipmentIds: number[];
  fitnessLevelId: number;
  freeTrialPeriod: boolean;
  freeTrialPeriodEndsAt: string;
  goals: Goal[];
  heightUnit: HeightUnit;
  height: number;
  id: number;
  lastUsedAt: string | null;
  locale: LOCALE;
  longestStreak: number;
  medicalCondition: boolean;
  memberType: MemberType;
  mobileSubscription: {
    active: boolean;
    expiresAt: string;
    trialPeriodEndsAt: string;
    trialPeriodAvailable: boolean;
  };
  nonSubscriberRights: boolean;
  npsQuestionCategory: NpsQuestionCategory;
  pauseMonthsLeft: number;
  phoneNumber: string;
  preferredWorkoutDays: PreferredWorkoutDay[];
  questionnaires: { key: QuestionnairesKey; completed: boolean }[]; // TODO the key: QuestionnaireKey will have to change to just string, as we get the mandatory questionnaire from API
  referralCode: ReferralCode;
  showNpsQuestion: boolean;
  subscription: MemberSubscription;
  subscriptionActive: boolean;
  timezone: string;
  totalAvailableCalls: number;
  trainerGender: Gender | null;
  trainersEmails: string[];
  trainingPlanDisabled: boolean;
  visibility: MemberVisibility;
  weight: number;
  weightUnit: WeightUnit;
}
