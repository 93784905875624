import { computed, type Ref, toValue } from "vue";
import parsePhoneNumber from "libphonenumber-js";
import type { PhoneNumberData } from "@/types/MemberTypes";

export function parsePhoneNo(phoneNo: string): PhoneNumberData {
  if (!phoneNo) {
    return {
      value: "",
      countryCode: "",
      isValid: false,
      e164: "",
    };
  }
  const parsedPhNumber: any = parsePhoneNumber(phoneNo);
  return {
    value: parsedPhNumber.number,
    countryCode: parsedPhNumber.country,
    isValid: true,
    e164: parsedPhNumber.number,
  };
}

export function usePhoneNumber(phNr: Ref<PhoneNumberData>, movedOn: Ref<boolean>, changeCb?: () => void) {
  const resetPhoneNumber = (): void => {
    toValue(phNr).value = "";
    toValue(phNr).isValid = false;
    toValue(phNr).e164 = "";

    if (changeCb) changeCb();
  };

  const updatePhoneNumber = (event: any): void => {
    toValue(phNr).isValid = event.isValid;
    toValue(phNr).e164 = event.e164;

    if (changeCb) changeCb();
  };

  const updatePhoneNumberCountryCode = (cc: string): void => {
    if (cc === toValue(phNr).countryCode) return;
    resetPhoneNumber();
    toValue(phNr).countryCode = cc;

    if (changeCb) changeCb();
  };

  const isPhoneNumberErrored = computed<boolean>(() => {
    return !toValue(phNr).isValid && movedOn.value;
  });

  return { updatePhoneNumber, updatePhoneNumberCountryCode, parsePhoneNo, isPhoneNumberErrored };
}
