<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { StatusMarkerType } from "@/stores/checkup-results";
import GraphLine from "@/components/blood-drop/GraphLine.vue";
import StatusIcon from "./StatusIcon.vue";

const { t } = useI18n();

const statusTexts = [
  t("SECTION.BLOOD_DROP.SECTIONS.GRAPH_STATUS_CARD.TEXT_CRITICAL_LOW"),
  t("SECTION.BLOOD_DROP.SECTIONS.GRAPH_STATUS_CARD.TEXT_LOW"),
  t("SECTION.BLOOD_DROP.SECTIONS.GRAPH_STATUS_CARD.TEXT_LOWER"),
  t("SECTION.BLOOD_DROP.SECTIONS.GRAPH_STATUS_CARD.TEXT_OPTIMAL"),
  t("SECTION.BLOOD_DROP.SECTIONS.GRAPH_STATUS_CARD.TEXT_HIGHER"),
  t("SECTION.BLOOD_DROP.SECTIONS.GRAPH_STATUS_CARD.TEXT_HIGH"),
  t("SECTION.BLOOD_DROP.SECTIONS.GRAPH_STATUS_CARD.TEXT_CRITICAL_HIGH"),
] as string[];

const statusTitles = computed(() => {
  return Object.values(StatusMarkerType).map((status: any) => {
    return status
      .replace(/([A-Z])/g, " $1")
      .replace(/^\w/, (c: any) => c.toUpperCase())
      .trim();
  });
});
</script>
<template>
  <div class="graph-container">
    <div class="title">
      <h2>{{ t("SECTION.BLOOD_DROP.SECTIONS.GRAPH_LEGEND_TITLE") }}</h2>
    </div>
    <GraphLine :showSlider="false" :valuePercent="0" />
    <div class="status-types-wrapper">
      <ul>
        <li v-for="status in statusTitles" :key="status">
          {{ status }}
        </li>
      </ul>
    </div>
    <div class="status-icons" v-for="(status, index) in Object.values(StatusMarkerType)" :key="status">
      <StatusIcon :status="status" class="status" />
      <div class="text" v-if="statusTexts[index]">
        <span>{{ statusTexts[index] }}</span>
      </div>
    </div>
    <div class="aditional-text">
      <p>{{ t("SECTION.BLOOD_DROP.SECTIONS.GRAPH_STATUS_CARD.ADITIONAL_TEXT1") }}</p>
      <p>{{ t("SECTION.BLOOD_DROP.SECTIONS.GRAPH_STATUS_CARD.ADITIONAL_TEXT2") }}</p>
    </div>
  </div>
</template>
<style lang="pcss" scoped>
@import "@/assets/base.css";
.graph-container {
  overflow-y: auto;
  box-sizing: border-box;
  margin-top: 1.5em;
  padding-bottom: 2.5em;
  border-top: 1px solid var(--clr-grey-3);
  .status-types-wrapper {
  ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin-top: 1em;
    margin-bottom: 2em;

    li {
      font-size: 0.8rem;
      }
    }
  }

 .status-icons {
    display: flex;
    gap: 0.5em;
    margin-bottom: 1em;
    align-items: center;
    .status {
      max-width: 9em;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .text {
      font-size: 0.8em;
    }
 }

.aditional-text {
    p {
      color: #939aa3;
      font-size: 0.8rem;
      margin: 0;
    }
  }
}
</style>
