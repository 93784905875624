<script setup lang="ts">
import SettingsInput from "@/components/ui/SettingsInput.vue";
import { HeightImperialUnit } from "@/types/MemberTypes";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  value: { [key in HeightImperialUnit]: "" };
  errors: {
    feet: boolean;
    inches: boolean;
  };
}>();

const emit = defineEmits<{
  (e: "change", value: { val: string; type: HeightImperialUnit }): void;
}>();

const { t } = useI18n();

const emitChange = (val: string, type: HeightImperialUnit) => {
  emit("change", { val, type });
};
</script>

<template>
  <div class="feet-inch-container">
    <SettingsInput
      :inputName="'height-feet'"
      :labelText="t('SETTINGS.PROFILE.HEIGHT_TYPE.FEET')"
      :value="props.value.feet"
      :errored="props.errors.feet"
      @update="emitChange($event, HeightImperialUnit.FEET)"
      :maxlength="1"
    />
    <SettingsInput
      :inputName="'height-inches'"
      :labelText="t('SETTINGS.PROFILE.HEIGHT_TYPE.INCHES')"
      :value="props.value.inches"
      :errored="props.errors.inches"
      @update="emitChange($event, HeightImperialUnit.INCHES)"
      :maxlength="2"
    />
  </div>
</template>

<style scoped lang="pcss">
.feet-inch-container {
  width: 100%;
  gap: 1em;
  display: flex;
  justify-content: center;
}
</style>
