<script setup lang="ts">
import { ref, computed, onMounted } from "vue";

const props = withDefaults(
  defineProps<{
    valuePercent: number;
    showSlider?: boolean;
  }>(),
  {
    showSlider: true,
  }
);

const graph = ref<HTMLElement | null>(null);
const slider = ref<HTMLElement | null>(null);
const sliderWidthAndHeight = 1.2;
const sliderWidthAndHeightEm = `${sliderWidthAndHeight}em`;

onMounted(() => {
  slider.value && updateGraphWidthAndSlider();
});

const variables = computed(() => {
  return `--slider-dim: ${sliderWidthAndHeightEm}`;
});

const updateGraphWidth = () => {
  const graphRect = graph.value!.getBoundingClientRect();
  return graphRect.width;
};

const updateSliderPosition = (graphWidth: number) => {
  const left = Math.round((graphWidth * props.valuePercent) / 100);
  slider.value!.style.left = `calc(${left}px - ${sliderWidthAndHeight / 2}em)`;
};

const pipe = (f: () => void, g: (arg: any) => void) => () => g(f());
const updateGraphWidthAndSlider = pipe(updateGraphWidth, updateSliderPosition);
</script>

<template>
  <div class="graph-line-container" :style="variables">
    <div class="graph" ref="graph"></div>
    <div v-if="props.showSlider" class="slider" ref="slider">
      <div class="black-point"></div>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.graph {
  position: relative;
  width: 100%;
  height: 0.5em;
  background: linear-gradient(
    90deg,
    rgb(73, 140, 219) 0%,
    rgb(107, 201, 209) 25%,
    rgb(91, 213, 128) 50%,
    rgb(254, 136, 86) 75%,
    rgb(254, 86, 86) 100%
  );
  border-radius: 0.25em;
}
.slider {
  position: relative;
  bottom: 0.9em;
  background-color: var(--clr-white-1);
  width: var(--slider-dim);
  height: var(--slider-dim);
  outline: 1px solid var(--clr-grey-3);
  border-radius: 0.6em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px -2px var(--clr-grey-4);
}
.slider .black-point {
  background-color: var(--clr-black-1);
  width: 0.3em;
  height: 0.3em;
  border-radius: 0.15em;
}
</style>
