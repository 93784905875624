<script setup lang="ts">
import { onUnmounted } from "vue";

const { modalType = "main" } = defineProps<{
  modalType?: "main" | "action";
  hasGradient?: boolean;
}>();

const emit = defineEmits<{
  (e: "clickOutside"): void;
}>();

const teleportModalTo = "body";

const emitClickOutside = () => {
  emit("clickOutside");
};

// ----- Hidden Body Overflow -----
const setOverflowPropOnBody = (val: "hidden" | "overlay") => {
  document.querySelector("body")?.style.setProperty("overflow", val);
};
setOverflowPropOnBody("hidden");
onUnmounted(() => setOverflowPropOnBody("overlay"));
// ----- Hidden Body Overflow -----
</script>

<template>
  <Teleport :to="teleportModalTo">
    <div class="modal-container" scroll="no">
      <div
        :class="{ modal: true, main: modalType === 'main', action: modalType === 'action' }"
        v-click-outside="emitClickOutside"
      >
        <slot></slot>
      </div>
      <div v-if="hasGradient" class="up-down-gradient"></div>
    </div>
  </Teleport>
</template>

<style scoped lang="pcss">

@import "@/assets/base.css";
@import "@/assets/styles/ui.css";

.up-down-gradient {
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0) 5%,
    rgba(255, 255, 255, 0) 90%,
    rgb(255, 255, 255) 100%
  );
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 1em;
  width: var(--modal-width);
  height: var(--modal-height);
  position: fixed;
  pointer-events: none;
}
.modal-container {
  --modal-width: 70vw;
  --modal-height: 75vh;
  font-family: Montserrat;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.modal {
  background-color: var(--clr-white-1);
  padding: 2.5em;
  border-radius: 1em;
  box-sizing: border-box;
  overflow-y: scroll;
}
.modal.main {
  width: var(--modal-width);
  height: var(--modal-height);
}
.modal.action {
  min-width: 20em;
  min-height: 10em;
}

@mixin scrollbar-two .modal, 7em, 10px, var(--clr-blue-1);

@media (--max-phone-width) {
  @mixin scrollbar-two .modal, 4em, 4px,var(--clr-blue-1);
}
</style>
