import { toValue, computed, watch, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import HeightVariation from "@/components/settings/HeightVariation.vue";
import SettingsInput from "@/components/ui/SettingsInput.vue";
import { transformFeetInchesToInches, transformCmToFeetInches, transformFeetInchesToCm } from "@/util/functions";
import { HeightUnit, HeightImperialUnit } from "@/types/MemberTypes";

export function useHeightFormats(
  stateData: {
    height_Cm: Ref<string>;
    height_Imp: Ref<{ [key in HeightImperialUnit]: string }>;
    heightUnit: Ref<HeightUnit>;
  },
  storeHeightUnit: () => HeightUnit,
  errors: () => { height_Cm: boolean; height_Imp: { [key in HeightImperialUnit]: boolean } },
  updateCmCb: (val: string) => void,
  updateImpCb?: (val: { [key in HeightImperialUnit]: string }) => void
) {
  const { t } = useI18n();

  const updateOtherSystemHeightOnUnitChange = (newUnit: HeightUnit) => {
    if (!toValue(storeHeightUnit)) return;
    if (newUnit === toValue(storeHeightUnit)) return;

    updateToOtherSystem(newUnit);
  };

  const updateToOtherSystem = (unit: HeightUnit) => {
    if (unit === HeightUnit.INCHES) {
      stateData.height_Imp.value = transformCmToFeetInches(stateData.height_Cm.value);
    } else if (unit === HeightUnit.CM) {
      stateData.height_Cm.value = transformFeetInchesToCm(stateData.height_Imp.value);
    }

    if (updateImpCb) updateImpCb(stateData.height_Imp.value);
    if (updateCmCb) updateCmCb(stateData.height_Cm.value);
  };

  watch(
    () => stateData.heightUnit.value,
    (newVal) => updateOtherSystemHeightOnUnitChange(newVal)
  );

  const updateHeightImp = ({ val, type }: { val: string; type: HeightImperialUnit }) => {
    stateData.height_Imp.value[type] = val;
    if (updateImpCb) updateImpCb(stateData.height_Imp.value);
    updateToOtherSystem(HeightUnit.CM);
  };

  const updateHeightCm = (val: string) => {
    stateData.height_Cm.value = val;
    if (updateCmCb) updateCmCb(val);
    updateToOtherSystem(HeightUnit.INCHES);
  };

  const getHeightInCmOrInches = () => {
    if (stateData.heightUnit.value === HeightUnit.CM) return stateData.height_Cm.value;
    if (stateData.heightUnit.value === HeightUnit.INCHES)
      return transformFeetInchesToInches(stateData.height_Imp.value);
    return "" as never;
  };

  const activeHeightComponent = computed<any>(() => {
    if (stateData.heightUnit.value === HeightUnit.INCHES) return HeightVariation;
    return SettingsInput;
  });

  const activeHeightComponentData = computed(() => {
    if (stateData.heightUnit.value === HeightUnit.INCHES) {
      return {
        props: {
          value: stateData.height_Imp.value,
          errors: toValue(errors().height_Imp) as { [key in HeightImperialUnit]: boolean },
        },
        on: {
          change: (e: { val: string; type: HeightImperialUnit }) => updateHeightImp(e),
        },
      };
    } else {
      return {
        props: {
          inputName: "height",
          labelText: t("SETTINGS.PROFILE.HEIGHT"),
          value: stateData.height_Cm.value,
          errored: toValue(errors().height_Cm),
          scrollOnFocus: true,
          maxLength: 5,
        },
        on: {
          update: (event: string) => updateHeightCm(event),
        },
      };
    }
  });

  return { activeHeightComponent, activeHeightComponentData, getHeightInCmOrInches };
}
