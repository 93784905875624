import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { API } from "@/util/api/api_paths";
import { axiosInstance } from "@/util/api/axios_instance";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { QuestionnairesKey, mandatoryQuestionnaireKeys } from "@/stores/questionnaires";
import type {
  AvatarPut,
  MemberProfile_Settings_PUT,
  ProfileInfoQuestionnaire_PUT,
} from "@/types/settings-page/Profile";
import {
  transformInchesToCm,
  transformInchesToFeetInches,
  transformCmToFeetInches,
  transformFeetInchesToInches,
} from "@/util/functions";
import { ProfileInfoMandatoryKeys } from "@/util/constants";
import { type MemberProfile, HeightUnit, WeightUnit, Gender } from "@/types/MemberTypes";

export const useMemberStore = defineStore("memberStore", () => {
  const profile = ref<MemberProfile | null>(null);

  const firstName = computed(() => {
    if (!profile.value) return "";
    return profile.value.account.firstName;
  });

  const fullName = computed(() => {
    if (!profile.value) return "";
    return `${profile.value.account.firstName} ${profile.value.account.lastName}`;
  });

  const getHeightInCm = computed(() => {
    if (!profile.value?.height) return "";
    if (profile.value.heightUnit === HeightUnit.CM) {
      return profile.value.height.toString();
    } else if (profile.value.heightUnit === HeightUnit.INCHES) {
      return transformInchesToCm(profile.value.height.toString());
    } else {
      return "" as never;
    }
  });

  const getHeightInFeetInches = computed(() => {
    if (!profile.value?.height) return { feet: "", inches: "" };

    if (profile.value.heightUnit === HeightUnit.CM) {
      return transformCmToFeetInches(profile.value.height.toString());
    } else if (profile.value.heightUnit === HeightUnit.INCHES) {
      return transformInchesToFeetInches(profile.value.height.toString());
    } else {
      return "" as never;
    }
  });

  const mandatoryUncompletedQuestionnaires = computed(() => {
    if (!profile.value || !profile.value.questionnaires) return [];

    const uncompleted = profile.value.questionnaires
      .map((quest: { key: QuestionnairesKey; completed: boolean }) => {
        if (mandatoryQuestionnaireKeys.includes(quest.key)) {
          if (!quest.completed) return quest.key;
        }
      })
      .filter((el) => !!el) as QuestionnairesKey[];

    return uncompleted;
  });

  const bloodTestUploadCredits = computed(() => {
    if (!profile.value) return 0 as never;
    return profile.value.bloodTestUploadCredits;
  });

  const mandatoryProfileInfo = computed(() => {
    return {
      firstName: profile.value ? profile.value.account.firstName : "",
      lastName: profile.value ? profile.value.account.lastName : "",
      gender: profile.value ? profile.value.account.gender : null,
      phoneNumber: profile.value ? profile.value.phoneNumber : null,
      birthday: profile.value ? profile.value.birthday : null,
      height: profile.value ? profile.value.height : null,
      weight: profile.value ? profile.value.weight : null,
      avatar: profile.value ? profile.value.avatar : null,
    };
  });

  const isMandatoryProfileInfoCompleted = computed(() => {
    if (!profile.value) return false as never;

    return Object.values(ProfileInfoMandatoryKeys)
      .map((value) => {
        if (value === ProfileInfoMandatoryKeys.GENDER) return true;
        if (value === ProfileInfoMandatoryKeys.AVATAR) return true;
        if (value === ProfileInfoMandatoryKeys.FIRST_NAME || value === ProfileInfoMandatoryKeys.LAST_NAME)
          return !!profile.value!.account[value];

        return !!profile.value![value];
      })
      .every((val) => !!val);
  });

  const fetchMemberProfile = async () => {
    try {
      const res = await axiosInstance.get(API.MEMBER_PROFILE);
      profile.value = camelcaseKeys(res.data.profile, { deep: true });

      return false;
    } catch (err) {
      console.error("Error on GET /profile API, ", err);
      return true;
    }
  };

  const updateMemberProfile = async (
    userInformation: MemberProfile_Settings_PUT
  ): Promise<[null | Error, null | MemberProfile]> => {
    const payload = snakecaseKeys({ profile: userInformation });
    try {
      // @ts-ignore
      if (!payload.profile.account.gender) payload.profile.account.gender = null; // * Handle Gender Non Binary as null

      const res = await axiosInstance.put(API.MEMBER_PROFILE, payload);
      profile.value = camelcaseKeys(res.data.profile, { deep: true });
      return [null, profile.value];
    } catch (err) {
      console.error("Error on PUT /profile API, ", err);
      return [new Error(), null];
    }
  };

  const serializeQuestionnaireProfileInfo = (questProfileInfo: any): Omit<ProfileInfoQuestionnaire_PUT, "avatar"> => {
    const payload: Omit<ProfileInfoQuestionnaire_PUT, "avatar"> = {
      account: { gender: Gender.CRAZY, firstName: "", lastName: "" },
      birthday: "",
      phoneNumber: "",
      weight: "",
      weightUnit: WeightUnit.KG,
      height: "",
      heightUnit: HeightUnit.CM,
    };
    payload.account.firstName = questProfileInfo.firstName;
    payload.account.lastName = questProfileInfo.lastName;
    payload.birthday = questProfileInfo.birthday;
    payload.phoneNumber = questProfileInfo.phoneNumber.e164;
    payload.weight = questProfileInfo.weight.value;
    payload.weightUnit = questProfileInfo.weight.weightUnit;

    if (questProfileInfo.height.heightUnit === HeightUnit.CM) {
      payload.height = questProfileInfo.height.height_Cm;
      payload.heightUnit = HeightUnit.CM;
    } else if (questProfileInfo.height.heightUnit === HeightUnit.INCHES) {
      payload.height = transformFeetInchesToInches(questProfileInfo.height.height_Imp);
      payload.heightUnit = HeightUnit.INCHES;
    }
    payload.account.gender = questProfileInfo.gender;
    return payload;
  };

  const updateMemberProfileQuestionnaireValues = async (userInformation: any) => {
    const payload = serializeQuestionnaireProfileInfo(userInformation);

    try {
      const snakedPayload = snakecaseKeys({ profile: payload });
      // @ts-ignore
      if (!snakedPayload.profile.account.gender) snakedPayload.profile.account.gender = null; // * Handle Gender Non Binary as null

      const res = await axiosInstance.put(API.MEMBER_PROFILE, snakedPayload);
      profile.value = camelcaseKeys(res.data.profile, { deep: true });
      return [null, profile.value];
    } catch (err) {
      console.error(`Error on PUT /profile from Questionnaire, ${err}`);
      return [true, null];
    }
  };

  const updateMemberProfileAvatarOnly = async (avatar: AvatarPut) => {
    try {
      const snakedPayload = snakecaseKeys({ profile: { avatar } });
      const res = await axiosInstance.put(API.MEMBER_PROFILE, snakedPayload);
      profile.value = camelcaseKeys(res.data.profile, { deep: true });
      return [null, profile.value];
    } catch (err) {
      console.error(`Error on PUT /profile from Questionnaire, ${err}`);
      return [true, null];
    }
  };

  return {
    // state
    profile,
    // computed
    firstName,
    fullName,
    getHeightInCm,
    getHeightInFeetInches,
    mandatoryUncompletedQuestionnaires,
    bloodTestUploadCredits,
    mandatoryProfileInfo,
    isMandatoryProfileInfoCompleted,
    // actions
    fetchMemberProfile,
    updateMemberProfile,
    serializeQuestionnaireProfileInfo,
    updateMemberProfileQuestionnaireValues,
    updateMemberProfileAvatarOnly,
  };
});
