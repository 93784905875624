import { reactive, computed, isRef, unref, type Ref } from "vue";
import { StatusMarkerType, type MarkerShort, type Marker, StatusMarkerTypeKeys } from "@/stores/checkup-results";

export function useStatusReference(marker: MarkerShort | Ref<Marker> | Ref<null>) {
  const unrefedMarker = computed<Marker | MarkerShort | null>(() => {
    if (isRef(marker) && !marker.value) return null;
    if (!marker) return null;
    if (isRef(marker) && marker.value) return unref(marker) as Marker;
    return marker as MarkerShort;
  });

  const referenceToRange = reactive<{ [key in StatusMarkerType]: [number, number] }>({
    criticalLow: [0, 0],
    low: [0, 0],
    lower: [0, 0],
    optimal: [0, 0],
    higher: [0, 0],
    high: [0, 0],
    criticalHigh: [0, 0],
  });

  const buildSectionRangesObject = () => {
    const sectionsLength = Object.keys(referenceToRange).length;
    Object.keys(referenceToRange).forEach((key: string, index) => {
      referenceToRange[key as StatusMarkerType] = [
        Math.round((index / sectionsLength) * 100) / 100,
        Math.round(((index + 1) / sectionsLength) * 100) / 100,
      ];
    });
  };

  buildSectionRangesObject();

  const statusType = computed<StatusMarkerType | null>(() => {
    if (!unrefedMarker.value) return null;

    if (unrefedMarker.value.range) return StatusMarkerTypeKeys[unrefedMarker.value.range];
    else return null;
    // * It was decided to not show anything if the BE does not return a range value in order to not risk any incosistencies between platforms.

    // // We should normally not get this far, this is Fallback #1

    // const value = parseFloat(unrefedMarker.value.value);
    // const refValues = unrefedMarker.value.referenceValues;
    // if (!value || !Object.keys(refValues).length) return null;

    // const compare = (value: number, min: string | null, max: string | null) => {
    //   if (min === null || max === null) return;
    //   return value >= parseFloat(min) && value <= parseFloat(max);
    // };

    // if (compare(value, refValues.criticalHigh.min, refValues.criticalHigh.max)) {
    //   return StatusMarkerType.CRITICAL_HIGH;
    // } else if (compare(value, refValues.high.min, refValues.high.max)) {
    //   return StatusMarkerType.HIGH;
    // } else if (compare(value, refValues.higher.min, refValues.higher.max)) {
    //   return StatusMarkerType.HIGHER;
    // } else if (compare(value, refValues.optimal.min, refValues.optimal.max)) {
    //   return StatusMarkerType.OPTIMAL;
    // } else if (compare(value, refValues.lower.min, refValues.lower.max)) {
    //   return StatusMarkerType.LOWER;
    // } else if (compare(value, refValues.low.min, refValues.low.max)) {
    //   return StatusMarkerType.LOW;
    // } else if (compare(value, refValues.criticalLow.min, refValues.criticalLow.max)) {
    //   return StatusMarkerType.CRITICAL_LOW;
    //   // We should really not get here, this is Fallback #2
    //   // I decided to only show LOW or HIGH values as I don't think is a good idea for a FE developer to decide
    //   // to how much weight each intermediate value has (if it is not between any ranges) and decide if it is
    //   // critical low or low for example, even if I know that it is somewhere between this 2 ranges 🐱‍🏍
    // } else if (refValues.optimal.min !== null && value < parseFloat(refValues.optimal.min)) {
    //   return StatusMarkerType.LOW;
    // } else if (refValues.optimal.max !== null && value > parseFloat(refValues.optimal.max)) {
    //   return StatusMarkerType.HIGH;
    // } else {
    //   return StatusMarkerType.OPTIMAL;
    // }
  });

  const markerReferenceValues = computed<string>(() => {
    if (!unrefedMarker.value) return "";
    if (!Object.keys(unrefedMarker.value.referenceValues).length) return "";
    return `${unrefedMarker.value.referenceValues.optimal.min} - ${unrefedMarker.value.referenceValues.optimal.max} ${unrefedMarker.value.unit}`;
  });

  const setPercentBasedOnlyOnStatusIcon = (range: [number, number]) => {
    return ((range[1] - range[0]) * 100) / 2 + range[0] * 100;
  };

  const percentValue = computed<number>(() => {
    if (!unrefedMarker.value || !statusType.value) return 0;

    const value = parseFloat(unrefedMarker.value.value);
    const refValues = unrefedMarker.value.referenceValues;
    if (!Object.keys(refValues).length) {
      return setPercentBasedOnlyOnStatusIcon(referenceToRange[statusType.value]);
    }

    const range = referenceToRange[statusType.value];
    const min = refValues[statusType.value].min !== null ? parseFloat(refValues[statusType.value].min!) : null;
    const max = refValues[statusType.value].max !== null ? parseFloat(refValues[statusType.value].max!) : null;

    // ----- This 2 options should never happen, this only validates staging bad data -----
    if (min === null || max === null) return setPercentBasedOnlyOnStatusIcon(range);
    if (value < min || value > max) {
      if (value < min && statusType.value === StatusMarkerType.CRITICAL_LOW) return 0;
      if (value > max && statusType.value === StatusMarkerType.CRITICAL_HIGH) return 100;
      return setPercentBasedOnlyOnStatusIcon(range);
    }
    // ----- This 2 options should never happen, this only validates staging bad data -----

    const percentInSubrange = Math.round((((value - min) * 100) / (max - min)) * 100) / 100;
    const raiseFactor = 100 / (range[1] * 100 - range[0] * 100);
    const totalPercent = ((percentInSubrange * 2) / 100) * raiseFactor + range[0] * 100;
    return totalPercent;
  });

  return { statusType, percentValue, markerReferenceValues };
}
